import React, { Component } from "react";
import "./companyformeditor.scss";
import { EmptyInfo, LoadingMask } from "../../../common";
import ImgSrc from "../../../assets/images/company.svg";
import { Button, Col, Divider, Input, Row, Select, Space, Typography, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddNewPhoneNumber from "./addNewNumber";
// import Select from "react-select";

const { TextArea } = Input;
const { Title } = Typography

class CompanyFormEditorNew extends Component {
  state = {
    addNewNumber: false
  }
  hideAddNumberModel = () => {
    this.setState(st => ({ ...st, addNewNumber: false }))
  }
  viewRender = () => {
    const {
      companyForm,
      labels,
      onFormFieldChange,
      onFormSelectChange,
      industries,
      countries,
      userPhoneNumbers,
      onCompanySave,
      onAvatarChange,
      getImgSrc,
      onFormFieldBlur,
      handleDelete
    } = this.props;

    if (companyForm && companyForm.UserCompanyId) {
      const {
        CompanyName,
        invalidCompanyName,
        CompanyAddress,
        invalidUserPhonenumberId,
        IndustryId,
        UserPhonenumberId,
        CountryId,
        WebUrl,
        Detail,
        Vat,
        IsDefaultCompany,
      } = companyForm;
      const avatar = companyForm && companyForm.Logo;
      const isMobile = window?.innerWidth > 767;
      const uploadProps = {
        beforeUpload: (file) => {
          onAvatarChange(file)
          return false;
        },
        fileList: [],
        maxCount: 1,
      };

      return (
        <>
          <AddNewPhoneNumber open={this?.state?.addNewNumber} hideAddNumberModel={this.hideAddNumberModel}
            title="Add Phone Number"
            heading="Add your new phone number here"
            getNumber={this?.props?.getNumber}
          />
          <div className="h-100 flex flex-column w-100">
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <div className="d-flex justify-content-between gap-8 align-items-center" style={{ padding: "16px", borderBottom: "1px solid #f4f2fe" }}>
                <Title level={5} className="m-0 text-clamp flex-1">{companyForm?.UserCompanyId != -1 ? companyForm?.CompanyName : "Add New Workplace"}</Title>
                {(companyForm?.UserCompanyId != -1 && isMobile) &&
                  < Title level={5} style={{ color: "#FF6363", cursor: "pointer" }} className="m-0"
                    onClick={() => handleDelete({ company: companyForm })}
                  >Delete Workplace</Title>
                }
              </div>
              <div style={{ overflow: "auto", padding: "16px" }}>
                <div className="companyBigIcon d-flex align-items-center gap-16">
                  {/* {avatar && <button className="closeBtn" onClick={getImgSrc} />} */}
                  <div className={`dashItemImg  ${avatar ? "" : "no-dp"}`}
                    style={{ margin: "unset" }}
                  >
                    <img
                      className="roundeImg"
                      src={avatar ? avatar : ImgSrc}
                      alt="company"
                    />
                  </div>
                  <Upload {...uploadProps}>
                    <Button
                      className="upload-btn-img"
                      size="middle"
                      type="text"
                    >
                      Add Image
                    </Button>
                  </Upload>
                  {/* <div className="uploadBtn" test-data-id="company-avatar-btn">
                    <input
                      test-data-id="company-avatar-input"
                      type="file"
                      name="companyAvatar"
                      className="input-upload-file"
                      onChange={onAvatarChange}
                      accept="image/*"
                    />
                  </div> */}
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <p className="mb-0 fnt-s-14"> {labels.NAME_LABEL}</p>
                  <Input
                    autoFocus={invalidCompanyName}
                    type="text"
                    name="CompanyName"
                    placeholder={labels.companyNamePlaceholder}
                    onChange={onFormFieldChange}
                    onBlur={onFormFieldChange}
                    test-data-id="input-text-company-name"
                    maxLength="50"
                    size="large"
                    autoComplete="off"
                    className={`bg-color-for-opportunity  ${invalidCompanyName ? "inValid" : ""
                      }`}
                    value={CompanyName ? CompanyName : ""}
                    bordered={false}
                  />
                </div>
                <Row gutter={[12, 8]} className="container-collapse" style={{ marginBottom: "16px" }}>
                  <Col xs={24} md={12}>
                    <div>
                      <p className="mb-0 fnt-s-14"> {labels.ADDRESS_LABEL}</p>
                      <Input
                        type="text"
                        name="CompanyAddress"
                        className="bg-color-for-opportunity"
                        value={CompanyAddress ? CompanyAddress : ""}
                        placeholder={labels.companyAddressPlaceholder}
                        onChange={onFormFieldChange}
                        test-data-id="input-text-company-Address"
                        maxLength="100"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div >
                      <p className="mb-0 fnt-s-14"> {labels.PHONE_LABEL}</p>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        autoFocus={invalidUserPhonenumberId}
                        className={`bg-color-for-opportunity  ${invalidUserPhonenumberId ? "inValid" : ""
                          }`}
                        showArrow
                        name="UserPhonenumberId"
                        value={UserPhonenumberId && UserPhonenumberId}
                        placeholder={labels.companyPhoneNumberPlaceholder}
                        promptTextCreator={(value) =>
                          `Create New Number ${value}`
                        }
                        onChange={(e, selectedOption) =>
                          onFormSelectChange("UserPhonenumberId", selectedOption)
                        }
                        autoBlur={true}
                        options={userPhoneNumbers}
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                              <Button type="text" className="d-flex align-items-center" icon={<PlusOutlined />} onClick={() => {
                                this.setState(st => ({
                                  ...st,
                                  addNewNumber: true
                                }))
                              }}>
                                Add Phone number
                              </Button>
                            </Space>
                          </>
                        )}
                      ></Select>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[12, 8]} className="container-collapse" style={{ marginBottom: "16px" }}>
                  <Col xs={24} md={12}>
                    <div >
                      <p className="mb-0 fnt-s-14"> {labels.INDUSTRY_LABEL}</p>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        value={IndustryId && IndustryId}
                        placeholder={labels.companyIndustryPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange("IndustryId", selectedOption)
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        options={industries}
                      ></Select>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <p className="mb-0 fnt-s-14"> {labels.COUNTRY_LABEL}</p>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        showArrow
                        className="bg-color-for-opportunity"
                        bordered={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.props?.label
                            ?.toLowerCase()
                            ?.indexOf(input?.toLowerCase()) >= 0
                        }
                        name="CountryId"
                        value={CountryId && CountryId}
                        placeholder={labels.companyCountryPlaceholder}
                        onChange={(e, selectedOption) =>
                          onFormSelectChange("CountryId", selectedOption)
                        }
                        options={countries}
                      ></Select>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[12, 8]} className="container-collapse" style={{ marginBottom: "16px" }}>
                  <Col xs={24} md={12}>
                    <div >
                      <p className="mb-0 fnt-s-14"> {labels.WEB_URL_LABEL}</p>
                      <Input
                        type="text"
                        name="WebUrl"
                        value={WebUrl ? WebUrl : ""}
                        placeholder={labels.companyURLPlaceholder}
                        onChange={onFormFieldChange}
                        onBlur={onFormFieldBlur}
                        test-data-id="input-text-company-weburl"
                        maxLength="100"
                        className="bg-color-for-opportunity"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className=" ">
                      <p className="mb-0 fnt-s-14"> {labels.VAT_LABEL}</p>
                      <Input
                        type="text"
                        name="Vat"
                        value={Vat ? Vat : ""}
                        placeholder={labels.companyVATPlaceholder}
                        onChange={onFormFieldChange}
                        test-data-id="input-text-company-Vat"
                        maxLength="50"
                        className="bg-color-for-opportunity"
                        size="large"
                        autoComplete="off"
                        bordered={false}
                      />
                    </div>
                  </Col>
                </Row>
                <div style={{ marginBottom: "16px" }}>
                  <p className="mb-0 fnt-s-14">Workplace Description</p>
                  <TextArea
                    name="Detail"
                    value={Detail ? Detail : ""}
                    placeholder={labels.companyDescriptionPlaceholder}
                    onChange={onFormFieldChange}
                    test-data-id="input-button-company-detail"
                    rows={6}
                    className="bg-color-for-opportunity"
                    bordered={false}
                  />
                </div>
                <div className=" d-flex justify-content-between gap-8"
                >
                  <label className="companyCheckboxLabel">
                    <Input
                      type="checkbox"
                      name="IsDefaultCompany"
                      className={
                        IsDefaultCompany ? "checked" : "input-checkbox"
                      }
                      value={IsDefaultCompany}
                      checked={IsDefaultCompany ? IsDefaultCompany : false}
                      onChange={onFormFieldChange}
                      test-data-id="input-checkbox-company-defaultCompany"
                    />
                    {labels.companyCheckboxMakeDefault}
                  </label>

                </div>
              </div>
              <div className="w-100 d-flex justify-content-end gap-16" style={{ padding: "12px", borderTop: "1px solid #f4f2fe" }}>
                {(companyForm?.UserCompanyId != -1 && !isMobile) &&
                  <button
                    className={`${!isMobile ? "w-100" : ""} button-footer-delete pl-4 pr-4`}
                    onClick={() => handleDelete({ company: companyForm })}
                  >
                    Delete
                  </button>
                }
                <button
                  className={`${!isMobile ? "w-100" : ""} button-footer-opportunity pl-4 pr-4`}
                  onClick={onCompanySave}
                >
                  Save
                </button>
              </div>
            </div>

          </div >
        </>
      );
    }
    return (
      <EmptyInfo testId="company-empty-detail">
        {labels.EMPTY_COMPANY_INFO}
      </EmptyInfo>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="company-form-editor">
        {isLoading && <LoadingMask />}
        {this.viewRender()}
      </div>
    );
  }
}

export default CompanyFormEditorNew;
