import React, { useEffect, useState, useRef } from "react";
import uuidv1 from "uuid/v1";
import AsyncSelect from "react-select/lib/Async";
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";
import {
  getRolesApi,
  getSkillsApi,
  getCertificationsApi,
  getIndustriesApi,
  getAllLanguagesApi,
  getAllKeyWordsApi,
  resumeAddRoleApi,
  resumeAddSkillApi,
  addCertificationApi,
  getKeywordApi,
  addIndustryApi,
  addKeywordApi,
  addLanguageApi,
  resumeItemDeleteApi,
  getResumeCertificationsApi,
  getResumeIndustriesApi,
  getResumeLanguagesApi,
  getResumeKeywordsApi,
  getResumeProfilesApi,
  getResumeSkillsApi,
} from "../../../Resume/ResumeEdit/resumeEditApi";
import classes from "./skill.module.scss";
import CustomSelect from "../../common/select";
import SuggestedLookupsAccordian from "../../common/SuggestedLookupsAccordian";
import MenuOptions from "../../common/menuOptions";
import Eye from "../../../../assets/newResume/eye";
import Trash from "../../../../assets/newResume/trash";
import { Modal } from "../../../../components";
import { Col, Row, Tooltip } from "antd";
import { ConfirmDialog } from "../../../../common";
import { useDispatch } from "react-redux";
import EmptyResume from "../../../../common/emptyMessage/emptyResume";

const keywords = ["Software Design", "DBA-Database", "Computer Architecture"];

const AddSkillOption = ({
  skill,
  levelOptions,
  deleteSkill,
  updateSkillLevel,
  showSuggestions,
  onSkillSuggestionButtonClick,
  setSuggestionSkill,
  suggestionSkill,
  propValue,
  propId,
  componentName,
  hideEye,
  hideLevel,
  handleScroll,
}) => {
  let Level;
  let suggest;
  let itsDate;
  if (componentName !== "Language" && skill.ExperienceLevel) {
    if (skill.ExperienceLevel === 1) {
      Level = "Beginner";
    } else if (skill.ExperienceLevel === 2) {
      Level = "Proficient";
    } else if (skill.ExperienceLevel === 3) {
      Level = "Expert";
    }
  }
  if (componentName === "Language" && skill.ExperienceLevel) {
    if (skill.ExperienceLevel === 1) {
      Level = "Beginner";
    } else if (skill.ExperienceLevel === 2) {
      Level = "Proficient";
    } else if (skill.ExperienceLevel === 3) {
      Level = "Expert";
    }
  }

  if (skill.CertificationDate) {
    Level = skill.CertificationDate;
    itsDate = true;
  }
  if (componentName === "Role") {
    suggest = "Profile";
  } else suggest = componentName;

  return (
    <>
      <div className={classes.skillOptions}>
        {!hideEye && (
          <div className={classes.mobileEyeIcon}>
            <Eye
              color={
                showSuggestions &&
                suggestionSkill === skill[propValue] &&
                "#8F82F5"
              }
              onClick={() => {
                onSkillSuggestionButtonClick(
                  skill[propId],
                  suggest,
                  skill[propValue],
                  skill.uniqueId
                );
                setSuggestionSkill(skill[propValue]);
                if (suggestionSkill !== skill[propValue]) {
                  handleScroll();
                }
              }}
            />
          </div>
        )}
        <div className={classes.textBox}>
          <span> {skill[propValue]}</span>{" "}
        </div>
        {!hideLevel && (
          <CustomSelect
            options={levelOptions}
            width={"100%"}
            handleChange={(level) => updateSkillLevel(skill, level)}
            className={classes.desktopExperience}
            value={
              itsDate
                ? moment(skill.CertificationDate, "MM/DD/YYYY").format("YYYY")
                : Level
            }
          />
        )}
        <div className={classes.mobileExperience}>
          <MenuOptions
            value={Level ? Level : skill.ExperienceLevel}
            options={levelOptions}
            hideEye={hideEye}
            handleScroll={handleScroll}
            suggestionSkill={suggestionSkill}
            componentName={componentName}
            skill={skill}
            valueCertificete={
              itsDate
                ? moment(skill.CertificationDate, "MM/DD/YYYY").format("YYYY")
                : Level
            }
            propValue={propValue}
            onLevelClick={(level) => updateSkillLevel(skill, level)}
            onDeleteIconClick={() => deleteSkill(skill)}
          />
        </div>
        {!hideEye && (
          <div className={classes.desktopEyeIcon}>
            <Tooltip title="view suggestion">
              <Eye
                color={
                  showSuggestions &&
                  suggestionSkill === skill[propValue] &&
                  "#8F82F5"
                }
                onClick={() => {
                  onSkillSuggestionButtonClick(
                    skill[propId],
                    suggest,
                    skill[propValue],
                    skill.uniqueId
                  );
                  setSuggestionSkill(skill[propValue]);
                  if (suggestionSkill !== skill[propValue]) {
                    handleScroll();
                  }
                }}
              />
            </Tooltip>
          </div>
        )}
        <div className={classes.trashIcon} onClick={() => deleteSkill(skill)}>
          <Trash />
        </div>
      </div>
    </>
  );
};

const Skill = (props) => {
  const {
    text: title,
    levelOptions,
    skillDefaultOptions,
    suggestedSkills,
    suggestedSkillsSKL,
    suggestedCertifications,
    suggestObject,
    onSkillSuggestionButtonClick,
    propValue,
    propId,
    componentName,
    hideEye,
    hideLevel,
    userDetails,
    notificationAction,
    onStateChangeAction,
  } = props;
  const dispatch = useDispatch();
  const [skills, setSkills] = useState([]);
  const [localSkills, setLocalSkills] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState("");
  const [suggestionSkill, setSuggestionSkill] = useState();
  const ref = useRef(null);
  const [deleteItem, setDeleteItem] = useState({
    show: false,
  });
  const [localSuggestedSkills, setLocalSuggestedSkills] = useState([]);
  const [allKeywordsList, setAllKeywordsList] = useState([]);
  const [resumeCurentItemList, setResumeCurentItemList] = useState([]);
  const { ResumeId } = userDetails;

  const getResumeProfiles = () => {
    getResumeProfilesApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.ProfileValue,
              value: i.ProfileId,
              uniqueId: uuidv1(),
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getResumeCertifications = () => {
    getResumeCertificationsApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((item) => {
            return {
              ...item,
              label: item.CertificationValue,
              value: item.CertificationId,
              uniqueId: uuidv1(),
              CertificationDate: moment(
                item.CertificationDate,
                "YYYY-MM-DDTHH:mm:ss"
              ).format("MM/DD/YYYY"),
              isCandidate: false,
              isInvalid: false,
              isActive: false,
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getResumeIndustries = () => {
    getResumeIndustriesApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.IndustryValue,
              value: i.IndustryId,
              uniqueId: uuidv1(),
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getResumeLanguages = () => {
    getResumeLanguagesApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.LanguageValue,
              value: i.LanguageId,
              uniqueId: uuidv1(),
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getResumeKeywords = () => {
    getResumeKeywordsApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.KeywordValue,
              value: i.KeywordId,
              uniqueId: uuidv1(),
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getAllKeyWordsLookup = () => {
    getAllKeyWordsApi().then((data) => {
      if (data.success) {
        setAllKeywordsList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.KeywordValue,
              value: i.KeywordId
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };

  const getResumeSkills = () => {
    getResumeSkillsApi().then((data) => {
      if (data.success) {
        setResumeCurentItemList(
          data.items.map((i) => {
            return {
              ...i,
              label: i.SkillValue,
              value: i.SkillId,
              uniqueId: uuidv1(),
            };
          })
        );
        onStateChangeAction({ isLoading: false });
      }
    });
  };


  const handleSkillsLoad = async (searchKey, callback) => {
    try {
      let options = [];
  
      if (componentName === "Language") {
        const response = await getAllLanguagesApi({ searchKey });
        if (response.success) {
          options = response?.items?.map((Skill) => ({
            ...Skill,
            label: Skill[propValue],
            value: Skill[propId],
          }));
        }
      } else if (isEmpty(searchKey)) {
        options = skillDefaultOptions?.map((Skill) => ({
          ...Skill,
          label: Skill[propValue],
          value: Skill[propId],
        }));
      } else if (componentName === "Keyword") {
        options = allKeywordsList
          .filter((i) => i?.label?.toLowerCase()?.includes(searchKey?.toLowerCase()))
          .map((Skill) => ({
            ...Skill,
            label: Skill[propValue],
            value: Skill[propId],
          }));
      } else {
        if (componentName === "Skill") {
          const response = await getSkillsApi({ searchKey });
          if (response.success) {
            options = response.items.map((Skill) => ({
              ...Skill,
              label: Skill[propValue],
              value: Skill[propId],
            }));
          }
        } else if (componentName === "Keyword") {
          const response = await getKeywordApi(searchKey);
          if (response.success) {
            const filteredItems = response.items.filter(
              (element) => element.SkillId !== skills.SkillId
            );
            options = filteredItems.map((Skill) => ({
              ...Skill,
              label: Skill[propValue],
              value: Skill[propId],
            }));
          }
        } else if (componentName === "Role") {
          const response = await getRolesApi({ searchKey });
          if (response.success) {
            options = response.items.map((Skill) => ({
              ...Skill,
              label: Skill[propValue],
              value: Skill[propId],
            }));
          }
        } else if (componentName === "Certification") {
          const response = await getCertificationsApi({ searchKey });
          if (response.success) {
            options = response.items.map((Skill) => ({
              ...Skill,
              label: Skill[propValue],
              value: Skill[propId],
            }));
          }
        } else if (componentName === "Industry") {
          const response = await getIndustriesApi({ searchKey });
          if (response.success) {
            options = response.items.map((Skill) => ({
              ...Skill,
              label: Skill[propValue],
              value: Skill[propId],
            }));
          }
        }
      }
  
      callback(null, { options });
    } catch (error) {
      console.error(error);
      callback(error);
    }
  };
  
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));



  const skillPostApi = (item) => {
    resumeAddSkillApi(item).then((data) => {
      if (data.success) {
        getResumeItems();
        if (item?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume skill updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      }
    });
  };
  const keywordPostApi = (item) => {
    addKeywordApi(item).then((data) => {
      if (data.success) {
        getResumeItems();
        if (item?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume skill updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      }
    });
  };
  const rolePostApi = (item) => {
    resumeAddRoleApi(item).then((data) => {
      if (data.success) {
        getResumeItems();
        if (item?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume role updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      }
    });
  };

  const certificationPostApi = (item) => {
    addCertificationApi(item).then((data) => {
      if (data.success) {
        getResumeItems();
        if (item?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume certification updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
      }
    });
  };

  const industryPostApi = (item) => {
    addIndustryApi(item).then((data) => {
      if (data.success) {
        getResumeItems();
        if (item?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume industry updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
        getResumeItems();
      }
    });
  };

  const languagePostApi = (payload) => {
    addLanguageApi(payload).then((data) => {
      if (data.success) {
        getResumeItems();
        if (payload?.ResumeId) {
          notificationAction({
            status: "success",
            message: "Resume language updated successfully!",
          });
        }
      } else {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
        getResumeItems();
      }
    });
  };

  const handleScroll = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const onDeleteSkillApi = (item, lookupItem) => {
    resumeItemDeleteApi({
      LookupTypeId: lookupItem.LookupTypeId,
      ResumeId: lookupItem.ResumeId,
      ObjectPK: lookupItem.ObjectPK,
    })
      .then((res) => {
        if (res.success) {
          deleteLookupFunc(item);
        } else {
          dispatch(
            notificationAction({
              status: "error",
              message:
                "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
            })
          );
          deleteLookupFunc(item);
        }
        setDeleteItem({ show: false });
      })
      .catch(() => {
        dispatch(
          notificationAction({
            status: "error",
            message:
              "sorry for inconvenience, please report this issue to our support team at support@prodoo.com",
          })
        );
        deleteLookupFunc(item);
      });
  };
  const onDeleteSkillMessage = (item) => {
    onStateChangeAction({ isLoading: true });
    switch (componentName) {
      case "Role":
        onDeleteSkillApi(item, {
          LookupTypeId: 1,
          ResumeId: ResumeId,
          ObjectPK: item.ProfileId,
        });
        break;
      case "Skill":
        onDeleteSkillApi(item, {
          LookupTypeId: 2,
          ResumeId: ResumeId,
          ObjectPK: item.SkillId,
        });
        break;
      case "Certification":
        onDeleteSkillApi(item, {
          LookupTypeId: 5,
          ResumeId: ResumeId,
          ObjectPK: item.CertificationId,
        });

        break;
      case "Industry":
        onDeleteSkillApi(item, {
          LookupTypeId: 4,
          ResumeId: ResumeId,
          ObjectPK: item.IndustryId,
        });
        break;
      case "Language":
        onDeleteSkillApi(item, {
          LookupTypeId: 6,
          ResumeId: ResumeId,
          ObjectPK: item.LanguageId,
        });

        break;
      case "Keyword":
        onDeleteSkillApi(item, {
          LookupTypeId: 3,
          ResumeId: ResumeId,
          ObjectPK: item.KeywordId,
        });

        break;
      default:
      // code block
    }
  };

  const onDeleteSkill = (item) => {
    switch (componentName) {
      case "Role":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));
        break;
      case "Skill":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));
        break;
      case "Certification":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));

        break;
      case "Industry":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));
        break;
      case "Language":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));
        break;
      case "Keyword":
        setDeleteItem((st) => ({
          ...st,
          show: true,
          message: "Are you sure, you want to delete this?",
          item: item,
        }));
        break;
      default:
      // code block
    }
  };

  const deleteLookupFunc = (itemVal) => {
    onStateChangeAction({ isLoading: false });
    getResumeItems();
    const updatedLocalSkills = localSkills
      .filter((skill) => skill.uniqueId !== itemVal.uniqueId)
      .map((skill, i) => ({
        ...skill,
        id: i,
      }));
    setLocalSkills(updatedLocalSkills);
    skills.map((item) => {
      if (
        item.uniqueId === itemVal.uniqueId &&
        item[propValue] === suggestionSkill
      ) {
        setSuggestionSkill("");
      }
    });

    setShowSuggestions((prev) => (prev === itemVal.uniqueId + 1 ? "" : prev));
  };
  const onUpdateSkillLevel = (item, level) => {
    onStateChangeAction({ isLoading: true });
    const { componentName } = props;
    let Level;

    if (componentName === "Language") {
      switch (level) {
        case "Beginner":
          Level = 1;
          break;

        case "Proficient":
          Level = 2;
          break;

        case "Expert":
          Level = 3;
          break;

        // case "Advanced":
        //   Level = 4;
        //   break;

        // case "Fluent":
        //   Level = 5;
        //   break;

        default:
          break;
      }
    } else {
      if (level === "Beginner") {
        Level = 1;
      } else if (level === "Proficient") {
        Level = 2;
      } else if (level === "Expert") {
        Level = 3;
      }
    }

    if (componentName === "Certification") {
      item.CertificationDate = moment(
        item.CertificationDate,
        "MM/DD/YYYY"
      ).year(level);
    } else {
      item.ExperienceLevel = Level;
    }

    switch (componentName) {
      case "Skill":
        skillPostApi(item);
        break;

      case "Role":
        rolePostApi(item);
        break;

      case "Certification":
        certificationPostApi(item);
        break;

      case "Industry":
        industryPostApi(item);
        break;

      case "Language":
        languagePostApi(item);
        break;

      default:
        break;
    }
    let updatedSkills = resumeCurentItemList.map((skill) => {
      if (skill.uniqueId === item.uniqueId) {
        return item;
      }
      return skill;
    });
    setResumeCurentItemList(updatedSkills);
  };

  const addNewSkill = () => {
    let Level;
    let Key;
    const checkItemExisted = resumeCurentItemList.some(
      (item) => item.value == selectedValue.value
    );
    if (checkItemExisted) {
      const info = {
        message: "Already Exists",
        status: "error",
      };
      notificationAction(info);
      return;
    }
    onStateChangeAction({ isLoading: true });
    switch (componentName) {
      case "Certification":
        Key = "CertificationDate";
        Level = Level = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
        break;
      case "Language":
        Key = "ExperienceLevel";
        Level = "Intermediate";
        break;
      default:
        Key = "ExperienceLevel";
        Level = "Expert";
        break;
    }
    switch (componentName) {
      case "Skill":
        skillPostApi({
          SkillId: selectedValue.SkillId,
          ExperienceLevel: 1,
        });
        break;
      case "Keyword":
        keywordPostApi({
          KeywordId: selectedValue.KeywordId,
          ExperienceLevel: 1,
        });
        break;

      case "Role":
        rolePostApi({
          ProfileId: selectedValue.ProfileId,
          ExperienceLevel: 1,
        });
        break;

      case "Certification":
        certificationPostApi({
          CertificationDate: new Date("2022").toISOString(),
          CertificationId: selectedValue.CertificationId,
        });
        break;

      case "Industry":
        industryPostApi({
          IndustryId: selectedValue.IndustryId,
          ExperienceLevel: 1,
          ResumeId: 0,
        });
        break;

      case "Language":
        languagePostApi({
          ExperienceLevel: 1,
          LanguageId: selectedValue.LanguageId,
        });
        break;

      default:
        break;
    }
    setSkills((prev) => [
      ...prev,
      {
        id: 0,
        [propValue]: selectedValue[propValue],
        [Key]: Level,
        keywords,
        [propId]: selectedValue[propId],
        ResumeProfileId: 0,
        isInvalid: false,
        isCandidate: false,
      },
    ]);
    setLocalSkills((prev) => [
      {
        id: 0,
        [propValue]: selectedValue[propValue],
        [Key]: Level,
        keywords,
        [propId]: selectedValue[propId],
        ResumeProfileId: 0,
        isInvalid: false,
        isCandidate: false,
      },
      ...prev,
    ]);
    setSelectedValue(`Select ${title}`);
  };
  const onAddSuggestedSkill = (skillId, suggestedSkill, type) => {
    const id = uuidv1();
    let Level;
    let Key;
    if (componentName === "Certification") {
      Key = "CertificationDate";
      Level = moment(new Date(), "MM/DD/YYYY").format("MM/DD/YYYY");
    } else {
      Key = "ExperienceLevel";
      Level = "Expert";
    }

    setSkills((prev) => [
      ...prev,
      {
        id: 0,
        [propValue]: suggestedSkill[propValue],
        [Key]: "Beginner",
        keywords,
        [propId]: selectedValue[propId],
        ResumeProfileId: 0,
        isInvalid: false,
        isCandidate: false,
      },
    ]);
    switch (type) {
      case "Roles":
        rolePostApi({
          ProfileId: suggestedSkill.ProfileId,
          ExperienceLevel: 1,
        });
        break;
      case "Skills":
        skillPostApi({
          SkillId: suggestedSkill.SkillId,
          ExperienceLevel: 1,
        });
        break;
      case "Certifications":
        certificationPostApi({
          CertificationDate: new Date("2022").toISOString(),
          CertificationId: suggestedSkill.CertificationId,
        });
        break;
      default:
    }
    setLocalSkills((prev) => [
      {
        uniqueId: id,
        id: 0,
        [propValue]: suggestedSkill[propValue],
        [Key]: "Beginner",
        keywords,
        [propId]: selectedValue[propId],
        ResumeProfileId: 0,
        isInvalid: false,
        isCandidate: false,
      },
      ...prev,
    ]);

    setLocalSuggestedSkills((prev) =>
      prev.filter((skill) => skill[propId] !== skillId)
    );

    setShowSuggestions((prev) => prev + 1);
  };

  useEffect(() => {
    getResumeItems();
  }, [componentName]);

  useEffect(() => {
    setSelectedValue(`Select ${title}`);
  }, [title]);

  // useEffect(() => {
  //   if (!skills.length) {
  //     setSkills([...localSkills, ...skillsList]);
  //   }
  // }, [skillsList]);

  useEffect(() => {
    if (suggestedSkills) {
      setLocalSuggestedSkills(suggestedSkills);
    }
  }, [suggestedSkills]);

  

  const getResumeItems = () => {
    switch (componentName) {
      case "Role":
        getResumeProfiles();
        break;
      case "Skill":
        getResumeSkills();
        break;
      case "Certification":
        getResumeCertifications();
        break;
      case "Industry":
        getResumeIndustries();
        break;
      case "Language":
        getResumeLanguages();
        break;
      case "Keyword":
        getResumeKeywords();
        getAllKeyWordsLookup();
        break;
      default:
    }
  };
  return (
    <>
      <Row className={classes.location} justify="center">
        <Col xs={24} lg={18} md={22}>
          <div className={classes.skill}>
            {/* <h1 className={classes.skillHead}>{title}</h1> */}
            <Row className="align-items-center mb-3">
              <Col md={18} xs={24}>
                <div className="EducationEditSelect">
                  <AsyncSelect
                    placeholder={
                      selectedValue[propValue]
                        ? selectedValue[propValue]
                        : `Select ${componentName}`
                    }
                    //cacheOptions
                   // defaultOptions
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    loadOptions={handleSkillsLoad}
                    onChange={handleChange}
                    value={selectedValue[propValue]}
                    //clearable
                  />
                  <div className={classes.addSkill}>
                    <button
                      className={classes.addSkillButton}
                      disabled={!selectedValue[propValue]}
                      onClick={() => addNewSkill()}
                    >
                      +
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            {resumeCurentItemList?.length > 0 ? (
              resumeCurentItemList?.map((item, ind) => (
                <AddSkillOption
                  key={ind}
                  skill={item}
                  levelOptions={levelOptions}
                  deleteSkill={onDeleteSkill}
                  updateSkillLevel={onUpdateSkillLevel}
                  showSuggestions={suggestedSkills?.length}
                  setShowSuggestions={setShowSuggestions}
                  onSkillSuggestionButtonClick={onSkillSuggestionButtonClick}
                  setSuggestionSkill={setSuggestionSkill}
                  suggestionSkill={suggestionSkill}
                  propValue={propValue}
                  propId={propId}
                  componentName={componentName}
                  hideEye={hideEye}
                  hideLevel={hideLevel}
                  handleScroll={handleScroll}
                />
              ))
            ) : (
              <EmptyResume text={`"No resume ${componentName} added yet"`} />
            )}

            <Modal
              className={`suggestedModal`}
              open={suggestionSkill && suggestedSkills != undefined}
              onCloseModal={() => setSuggestionSkill(null)}
            >
              <div className="about-page">
                <SuggestedLookupsAccordian
                  classes={classes}
                  suggestObject={suggestObject}
                  componentName={"Roles"}
                  localSuggestedSkills={localSuggestedSkills}
                  onAddSuggestions={onAddSuggestedSkill}
                  propId={propId}
                  propValue={propValue}
                />
                <SuggestedLookupsAccordian
                  classes={classes}
                  suggestObject={suggestObject}
                  componentName={"Skills"}
                  localSuggestedSkills={suggestedSkillsSKL}
                  onAddSuggestions={onAddSuggestedSkill}
                  propId={"SkillId"}
                  propValue={"SkillValue"}
                />
                <SuggestedLookupsAccordian
                  classes={classes}
                  suggestObject={suggestObject}
                  componentName={"Certifications"}
                  localSuggestedSkills={suggestedCertifications}
                  onAddSuggestions={onAddSuggestedSkill}
                  propId={"CertificationId"}
                  propValue={"CertificationValue"}
                />
              </div>
            </Modal>
            {deleteItem?.show && (
              <ConfirmDialog testId="company-confirm-diloag">
                <ConfirmDialog.Message>
                  {deleteItem?.message}
                </ConfirmDialog.Message>
                <ConfirmDialog.ActionButtons>
                  <ConfirmDialog.Button
                    className="dialog-btn"
                    testId="company-delete-yes"
                    onClick={() => {
                      onDeleteSkillMessage(deleteItem?.item);
                    }}
                  >
                    Yes
                  </ConfirmDialog.Button>
                  <ConfirmDialog.Button
                    className="dialog-btn"
                    testId="company-delete-no"
                    onClick={() => {
                      setDeleteItem((st) => ({
                        ...st,
                        show: false,
                        message: "",
                        item: "",
                      }));
                    }}
                  >
                    No
                  </ConfirmDialog.Button>
                </ConfirmDialog.ActionButtons>
              </ConfirmDialog>
            )}
          </div>
          <div ref={ref}></div>
        </Col>
      </Row>
    </>
  );
};

export default Skill;
