import { ActionTypes } from "../../actions";

export const onStateChangeAction = ({
  shortListResume,
  filteredShortlist,
  selected,
  shortListResumeList,
}) => (dispatch) => {
  console.log({selected})
  shortListResumeList !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SHORTLIST.SHORTLIST_LIST,
      payload: shortListResumeList,
    });
    filteredShortlist !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SHORTLIST.FILTERED_SHORTLIST,
      payload: filteredShortlist,
    });
  shortListResume !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SHORTLIST.SHORTLIST_RESUME,
      payload: shortListResume,
    });
  selected !== undefined &&
    dispatch({
      type: ActionTypes.NEWSEARCH.SHORTLIST.SELECTED,
      payload: selected,
    });
};
